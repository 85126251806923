<template>
  <div class="container">
    <div class="information">
      <!-- 1.销售渠道 -->
      <van-field name="radio" label="1.销售渠道">
        <template #input>
          <van-radio-group v-model="saleChannel" direction="horizontal">
            <van-radio v-for="option in saleChannelOptions" :key="option" :name="option">{{ option }}</van-radio>
          </van-radio-group>
        </template>
      </van-field>

      <!-- 2.检测状态 -->
      <van-field name="radio" label="2.检测状态">
        <template #input>
          <van-radio-group v-model="inspectionStatus" direction="horizontal">
            <van-radio v-for="option in inspectionStatusOptions" :key="option" :name="option">{{ option }}</van-radio>
          </van-radio-group>
        </template>
      </van-field>

      <!-- 3.收车附带 -->
      <van-field name="checkbox" label="3.收车附带">
        <template #input>
          <van-checkbox-group v-model="carAccessories" direction="horizontal">
            <van-checkbox v-for="option in carAccessoriesOptions" :key="option" :name="option">{{ option }}</van-checkbox>
          </van-checkbox-group>
        </template>
      </van-field>

      <!-- 4.增改项目 -->
      <van-field name="checkbox" label="4.增改项目">
        <template #input>
          <van-checkbox-group v-model="modifications" direction="horizontal">
            <van-checkbox v-for="option in modificationsOptions" :key="option" :name="option">{{ option }}</van-checkbox>
          </van-checkbox-group>
        </template>
      </van-field>

      <!-- 5.备注 -->
      <van-field v-model="otherNotice" label="5.备注" type="textarea" placeholder="请输入备注" rows="1"
                 autosize/>
    </div>

    <div class="button-box">
      <van-button class="btn" type="info" block round @click="copyText">复制文本</van-button>
      <van-button class="btn" type="primary" block round @click="clearText">清除文本</van-button>
    </div>

  </div>
</template>

<script>
import {Toast} from 'vant';

export default {
  name: "CheShangJi",
  data() {
    return {
      saleChannel: "",
      inspectionStatus: "",
      carAccessories: [],
      modifications: [],
      otherNotice: "",
      copiedText: "",
      saleChannelOptions: ["马利克已定", "马利克已售", "王启麟", "其他", "未定"],
      inspectionStatusOptions: ["卖家已检测", "外地已下单", "锦和需下单", "无需检测", "未定"],
      carAccessoriesOptions: ["钥匙1把", "钥匙2把", "行驶证", "登记证书", "税票", "身份证", "其他", "未定"],
      modificationsOptions: ["座椅加热", "方向盘加热", "电动座椅", "其他", "未定"]
    };
  },
  methods: {
    copyText() {
      const textToCopy = [
        `1. 销售渠道: ${this.saleChannel}`,
        `2. 检测状态: ${this.inspectionStatus}`,
        `3. 收车附带: ${this.carAccessories.join('、')}`,
        `4. 增改项目: ${this.modifications.join('、')}`,
        `5. 备注: ${this.otherNotice}`
      ].join('\n');

      const textarea = document.createElement("textarea");
      textarea.value = textToCopy;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);

      Toast.success('文本已成功复制到剪贴板');
      this.copiedText = textToCopy;
    },
    clearText() {
      this.saleChannel = "";
      this.inspectionStatus = "";
      this.carAccessories = [];
      this.modifications = [];
      this.otherNotice = "";
      this.copiedText = "";
    },
    saveData() {
      localStorage.setItem('cheShangData', JSON.stringify(this.$data));
    },
    loadData() {
      const savedData = localStorage.getItem('cheShangData');
      if (savedData) {
        let savedDataJSON = JSON.parse(savedData);
        Object.assign(this.$data, savedDataJSON);
      }
    },
  },
  created() {
    this.loadData();
  },
  watch: {
    saleChannel: {handler: 'saveData'},
    inspectionStatus: {handler: 'saveData'},
    carAccessories: {handler: 'saveData'},
    modifications: {handler: 'saveData'},
    otherNotice: {handler: 'saveData'},
  }
};
</script>

<style scoped>
.container {
  padding: 10px;
}

.btn {
  margin-bottom: 10px;
}

.button-box {
  margin: 20px;
}
</style>